import logo from './logo.svg';
import './App.css';
import React, {useState,useEffect} from 'react'
import VideoRecorder from 'react-video-recorder'
import DisconnectedView from './Disconnected';
import Actions from './defaults/render-actions';
import jwt_decode from "jwt-decode";
import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';


import {
  useParams,
} from "react-router-dom";
function App() {
  const API = 'https://us-central1-ramadan-competition.cloudfunctions.net/ramadancompetition-v2-first'
  //const API = '/api'
  // TODO: Replace the following with your app's Firebase project configuration
  const blob_url = process.env.REACT_APP_AZURE_BLOB_URL
  const azure_container = 'quran'
  
  const maxUpload = 5
  const { token, parentId, compType, regId } = useParams();
  console.log({ token, parentId, compType, regId })
  const [tokenValidated, setTokenVlidated] =  useState(false)
  const [tokenData, setTokenData] =  useState(null)
  const [videoBlob, setVideoBlob] =  useState(null)
  const [blob, setBlob] =  useState(null)
  const [uploading, setUploading] =  useState(false)
  const [uploadProgress, setUploadProgress] =  useState(0)
  const [blobUrl, setBlobUrl] =  useState(null)
  const [nextUploadCount, setNextUploadCount] =  useState(0)
  const [containerClient, setContainerClient] =  useState(null)
  const getBlobsInContainer = async (containerClient) => {
  
    const returnedBlobUrls = [];
    let found = null
    let foundCount = 0
    for await (const blob of containerClient.listBlobsFlat()) {
      for(let i=0;i<maxUpload;i++){
        const c = i>0?('-'+i):''
        if(blob.name === `${parentId}-${compType}-${regId}${c}.webm`){
          found = blob
          foundCount++
        }
      }
      
      //returnedBlobUrls.push(blob);
    }
    console.log('foundCount: ', foundCount)
    return foundCount;
  }
  const newRecording = ()=>{
    
    setBlob(false)
  }
  const uploadVideo = async (videoBlob)=>{
    setVideoBlob(videoBlob)
    console.log('videoBlob', videoBlob)
    const blobClient = containerClient.getBlockBlobClient(`${parentId}-${compType}-${regId}${nextUploadCount>0?'-'+nextUploadCount:''}.webm`);
    
    const options = { blobHTTPHeaders: { blobContentType: videoBlob.type }, onProgress: (e)=>{
      console.log("onProgress", e.loadedBytes, videoBlob.size)
      const done = e.loadedBytes/videoBlob.size
      if(done === 1){
        setUploading(false)
        setBlob(true)
        setNextUploadCount(nextUploadCount+1)
      }
      setUploadProgress(done*100)
    }};
    setUploading(true)
    await blobClient.uploadData(videoBlob, options)
  }
  useEffect(()=>{
    async function temp(){
    if(blobUrl){
      console.log('blobUrl', blobUrl)
      const blobService = new BlobServiceClient(
        blobUrl
      );
      const cc = blobService.getContainerClient(compType.toLowerCase())
      setContainerClient(cc)
      await cc.createIfNotExists({
        access: 'container',
      })
      if(!blob){
        const d = await getBlobsInContainer(cc)
        setBlob(d>0)
        setNextUploadCount(d)
      }
    }}
    temp()
    
  }, [blobUrl])

  
  useEffect(()=>{
    const path = `/token/${token}/${parentId}/${compType}/${regId}/validate`
      console.log(API+path)
    if(!tokenValidated){
      
      fetch(API+path)
      .then(res=>res.json())
      .then(async json=>{
        if(json.bloburl){
          
          var td = jwt_decode(token, { header: false });
          setTokenData(td)
          setTokenVlidated(true)
          setBlobUrl(json.bloburl)
          console.log(td)
        }
      })
      /*fetch("https://www.googleapis.com/robot/v1/metadata/x509/securetoken@system.gserviceaccount.com")
      .then(res=>res.json())
      .then(async json=>{
        console.log(json)
        var header = jwt_decode(token, { header: true });
        var kid = header.kid
        console.log(header, json[kid])

        
        jwtVerify(token, json[kid])
        .then((payload, protectedHeader)=>{
          console.log(payload, protectedHeader)
        })
      })*/
      
    }
  }, [tokenValidated])
  const doneMessage = maxUpload-nextUploadCount>0?<h2>Jazakallah Khair for submitted your recording, to submit a replacement click <a onClick={newRecording} style={{textDecoration:'underline', cursor:'pointer'}}>here</a>.  You have {maxUpload-nextUploadCount} tries left.</h2>:<h2>Jazakallah Khair for submitted your recording.</h2>
  return (
    <div className="App" style={{height:'100vh'}}>
      {tokenData?!blob?uploading?<h2>uploading video, please wait and do not close this page untill upload is complete.  <br/>{uploadProgress}% Completed.</h2>:<div style={{height:'100vh'}}><VideoRecorder 
        chunkSize={250}
        constraints={{
          audio: true,
          video: true
        }}
        countdownTime={3000}
        dataAvailableTimeout={500}
        timeLimit={15*60*1000}
        isFlipped
        mimeType={undefined}
        showReplayControls
        isOnInitially
        renderDisconnectedView={()=><DisconnectedView/>}
        renderActions={Actions}
        onRecordingComplete={async videoBlob => {
          // Do something with the video...
          
          uploadVideo(videoBlob)
         
        }}
      /></div>:doneMessage:<div>loading ...</div>}
    </div>
  );
}

export default App;
