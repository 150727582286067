import React, { useEffect } from "react";

function Redirect() {

  useEffect(() => {
    window.location.href = "https://register.islamiccompetition.com";
  }, []);

  return (
    <div>
    </div>
  );
}

export default Redirect;